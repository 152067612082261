<template>
  <div class="brandSituation">
    <div
      v-if="content"
      class="simpleInfoPanel"
    >
      <div class="brand-basic-msg">
        <h3 class="w-title-gradients">品牌基本信息</h3>
        <div class="brand-info">
          <div
            class="info-item"
            v-if="details.zongbuName!==''"
          >
            <span>所属企业：</span>
            <i>{{details.zongbuName}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.parentfirm!==''"
          >
            <span>所属集团：</span>
            <i>{{details.parentfirm}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.zongbuGuojia!==''"
          >
            <span>品牌国籍：</span>
            <i>{{details.zongbuGuojia}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.webSite!==''"
          >
            <span>品牌官网：</span>
            <a
              :href="details.webSite"
              target="_blank"
            >{{details.webSite}}</a>
          </div>
          <div
            class="info-item"
            v-if="details.zongbuCity!==''"
          >
            <span>总部城市：</span>
            <i>{{details.zongbuCity}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.dicBrandGrade"
          >
            <span>品牌档次：</span>
            <i>{{details.dicBrandGrade}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.brandClass"
          >
            <span>品牌级次：</span>
            <i>{{details.brandClass}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.createDate!==''"
          >
            <span>创立时间：</span>
            <i>{{details.createDate}}年</i>
          </div>
          <div
            class="info-item"
            v-if="details.firstTime"
          >
            <span>首进购物中心时间：</span>
            <i>{{details.firstTime}}年</i>
          </div>
          <div
            class="info-item"
            v-if="details.address!==''"
          >
            <span>总部地址：</span>
            <i>{{details.address}}</i>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="brand-basic-msg">
        <h3 class="w-title-gradients">开店经营数据</h3>
        <div class="brand-info">
          <div
            class="info-item"
            v-if="details.kaiDianMode"
          >
            <span>开店方式：</span>
            <i>{{details.kaiDianMode}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.listHzms"
          >
            <span>合作模式：</span>
            <i>{{details.listHzms}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.listHzfs"
          >
            <span>租赁方式：</span>
            <i>{{details.listHzfs}}</i>
          </div>
          <div
            class="info-item"
            v-if="details.qiXianMin && details.qiXianMin !== '0'"
          >
            <span>合作期限：</span>
            <i>{{details.qiXianMin}}-{{details.qiXianMax}}年</i>
          </div>
          <div
            class="info-item"
            v-if="details.kedanjiaMin && details.kedanjiaMax"
          >
            <span>客单价：</span>
            <i>{{details.kedanjiaMin}}-{{details.kedanjiaMax}}元</i>
          </div>
          <div
            class="info-item"
            v-if="details.renJunXFMin && details.renJunXFMax"
          >
            <span>人均消费：</span>
            <i>{{details.renJunXFMin}}-{{details.renJunXFMax}}元</i>
          </div>
          <div
            class="info-item"
            v-if="details.mianJiMin !== 0 && details.mianJiMax !== 0"
          >
            <span>开店面积：</span>
            <i>{{details.mianJiMin}}-{{details.mianJiMax}}平米</i>
          </div>
          <div
            class="info-item"
            v-if="details.yearNum !== 0"
          >
            <span>预计全年开店量：</span>
            <i>{{details.yearNum}}家</i>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div
      class="jurisdiction-wrap-no-data"
      v-if="defaultFalse"
    >
      <img
        src="@/assets/images/no_list_data.png"
        alt=""
      >
      <p>暂无品牌概况</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'brandSituation',
  data () {
    return {
      loading: true,
      guestGroup: {},
      wholeList: [],
      showMore: true,
      len: 0,
      content: false,
      defaultFalse: false
    }
  },
  props: {
    details: {
      type: Object
    }
  },
  components: {
  },
  mounted () {
    if (this.details.zongbuName ||
      this.details.parentfirm ||
      this.details.zongbuGuojia ||
      this.details.webSite ||
      this.details.zongbuCity ||
      this.details.dicBrandGrade ||
      this.details.createDate ||
      (this.details.firstTime !== '0' && this.details.firstTime) ||
      this.details.Address ||
      this.details.kaiDianMode ||
      this.details.listHzfs ||
      (this.details.qiXianMin && this.details.qiXianMin !== '0') ||
      this.details.renJunXFMin !== 0 ||
      this.details.mianJiMin !== 0 ||
      this.details.yearNum
    ) {
      this.defaultFalse = false
      this.content = true
    } else {
      this.defaultFalse = true
      this.content = false
    }
  },
  methods: {
    toTab (type) {
      this.$emit('tabClick', type)
    },
    toDetails (id, questionState, gbid) {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=4&questionState=' + questionState + '&gbid=' + gbid, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brandSituation
  .simpleInfoPanel
    width 100%
    padding-top 25px
    padding-bottom 50px
    border-radius 0 0 3px 3px
    .brand-basic-msg
      margin-bottom 30px
      .brand-info
        padding 0 42px
        max-width 1190px
        margin-top 30px
        .info-item
          float left
          min-width 32%
          margin-bottom 12px
          margin-right 10px
          span
            font-size 14px
            font-weight 500
            color #A5A5A7
            margin-right 5px
          i
            font-size 14px
            font-weight 500
            color #FFFFFF
            em
              border 1px solid #FFA134
              color #FFA134
              font-size 12px
              cursor pointer
          a
            color #fff
.competitive-brand
  margin-bottom 30px
  .brand-list
    ul
      padding 10px 0 10px 0
      overflow hidden
  .list-item
    position relative
    width 198px
    height 160px
    background-color #fff
    margin 8px 8px 0 0
    float left
    cursor pointer
  .list-item:nth-child(6n)
    margin-right 0
  .item-image
    display inline-block
    width 55px
    height 53px
    background no-repeat center center
    background-size 100%
    margin-left 15px
    margin-top 10px
  .item-info-xx
    display inline-block
    position absolute
    top 40px
    left 78px
    width 68px
    height 22px
    text-align center
    line-height 22px
    font-size 12px
    cursor pointer
    border 1px solid #eee
    border-radius 4px
    color #333
    background #fff
  .item-info-need
    font-size 12px
    color #333
    margin-left 15px
    margin-top 10px
    p
      line-height 20px
      padding 1px 0
  .item-brandName
    position absolute
    top 10px
    left 80px
    font-weight 600
    width 120px
    height 55px
    overflow hidden
    text-overflow ellipsis
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    font-size 14px
    color #333
  .brand-list
    padding 10px 26px
  .sameGroup
    width 40px
    height 24px
    line-height 24px
    position absolute
    right 0
    top 0
    background-color #FFA134
    color #fff
    font-size 12px
    text-align center
  .sameGroup2
    width 40px
    height 24px
    line-height 24px
    position absolute
    right 0
    top 0
    background-color rgba(255, 113, 52, 1)
    color #fff
    font-size 12px
    text-align center
  .star-tag
    position absolute
    left 3px
    top 3px
    width 20px
    height 27px
    background url('~@/assets/images/star-label.png') no-repeat
    background-size 100%
    z-index 111
  .brand-label-list
    position absolute
    left 15px
    bottom 8px
    font-size 12px
    span
      display inline-block
      font-size 12px
      color #b8a794
      padding 4px 6px
      border-radius 6px
      background-color #fff8f0
      max-width 150px
.brand-update-wrap
  .brand-update-table
    padding 0 32px
    margin-top 20px
    table
      thead
        tr
          height 42px
          line-height 42px
          border-bottom 1px solid #353539
          td
            font-size 14px
            font-weight 400
            color #A5A5A7
      tbody
        tr
          height 42px
          line-height 42px
          border-bottom 1px solid #353539
          td
            font-size 14px
            font-weight 400
            color #fff
.showBtn
  color #ffffff
  text-align center
  font-size 14px
  margin-top 20px
  cursor pointer
  text-decoration underline
.jurisdiction-wrap-no-data
  color #ffffff
  text-align center
  min-height 170px
  padding-top 80px
  background #272930
  padding-bottom 24px
  img
    width 136px
    height 98px
    margin-bottom 20px
  p
    font-size 14px
    line-height 18px
</style>
