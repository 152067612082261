<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="contactWay"
  >
    <div
      v-if="contactList && contactList.length > 0"
      class="simpleInfoPanel"
    >
      <!-- <div class="simpleBorderPanel">
        <i class="icon-prompt"></i>
        温馨提示：因业内人员流动性较大，系统内的海量品牌联系方式只作为招商参考信息。
        <p class="fr">您还可查看<span class="colorOrange"> {{lookNumber}} </span>个品牌的 “开关店分布 + 数据分析 + 拓展需求 + 联系方式数据”</p>
      </div> -->
      <el-select
        class="mt40"
        placeholder="请选择"
        v-model="area"
        popper-class="w-block-select-down"
        @change="handleArea"
      >
        <el-option
          v-for="item in projectArea"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button
        class="btnCommon btnPrimary ml15"
        @click="addContact()"
      >+ 添加联系方式</el-button>
      <ul class="contactCards">
        <li
          v-for="(item,index) in contactList"
          :key="index"
        >
          <span class="openMode">{{item.companyType==0?'直营':'代理'}}</span>
          <div>{{item.linkName==''?'-':item.linkName}}</div>
          <div>{{item.linkBuMen==''?'-':item.linkBuMen}}{{item.linkZhiwei==''?'-':item.linkZhiwei}}</div>
          <div
            class="ellipsis"
            :title="item.companyName"
          >{{item.companyName==''?'-':item.companyName}}</div>
          <p class="line"></p>
          <p
            class="ellipsis"
            :title="item.linkTuozhancity"
          ><span>负责区域：</span>{{item.linkTuozhancity==''?'-':item.linkTuozhancity}}</p>
          <p
            class="ellipsis"
            :title="[(item.mobile ? item.mobile: '-') + (item.mobile2 ? '/' + item.mobile2: '')]"
          ><span>手机号码：</span>{{item.mobile ? item.mobile: '-'}}{{item.mobile2 ? '/' + item.mobile2: ''}}</p>
          <p
            class="ellipsis"
            :title="item.email"
          ><span>电子邮箱：</span>{{item.email==''?'-':item.email}}</p>
          <p><span>办公电话：</span>{{item.linkTel==''?'-':item.linkTel}}{{item.linkTel2 ? '/' + item.linkTel2: ''}}</p>
          <!-- <template v-if="isShowBrandDetail">
            <el-button
              v-if="item.isLook === 0"
              class="btnCommon btnComonPlus"
              @click="lookLinkDetail(item)"
            >查看联系方式</el-button>
          </template>
          <template v-else>
            <el-button
              v-if="item.isLook === 0 && lookNumber > 0"
              class="btnCommon btnComonPlus"
              @click="lookLinkDetail(item)"
            >查看联系方式</el-button>
          </template>
          <i
            @click="removeContact(item)"
            class="el-icon-delete"
          ></i> -->
        </li>
      </ul>
    </div>
    <div
      class="contact-privilege"
      v-if="contactList && contactList.length === 0 && isHasContact"
    >
      <!-- <div class="amount-text">您还可发起 <span>{{interestsNum}}</span> 个品牌的联系特权</div> -->
      <div
        class="amount"
        v-if="showInterestsType === 1"
      >
        <img
          src="@/assets/images/no_list_data.png"
          imgtype='smallgoods'
          alt=""
        >
        <p class="p1">抱歉，当前品牌联系方式正在建设中</p>
        <p
          class="btn"
          @click="addContact()"
        > <span>添加联系方式</span> </p>
      </div>
      <div
        class="amount"
        v-else-if="showInterestsType === 2"
      >
        <img
          src="@/assets/images/no_list_data.png"
          imgtype='smallgoods'
          alt=""
        >
        <p class="p1">暂无联系方式</p>
        <!-- <p class="p-text"><em class="el-icon-warning-outline"></em>此品牌为非“连锁品牌”暂不支持发起联系特权</p> -->
        <p
          class="btn"
          @click="addContact()"
        > <span>添加联系方式</span> </p>
      </div>
      <div
        class="amount"
        v-else-if="showInterestsType === 3"
      >
        <img
          src="@/assets/images/no_list_data.png"
          imgtype='smallgoods'
          alt=""
        >
        <template v-if="!isEmptyContact">
          <p class="p1">抱歉，当前品牌联系方式正在建设中</p>
          <div class="tip-text">
            <p class="font13"><em class="el-icon-warning-outline"></em>您可使用“<i>联系特权</i>”，赢商会会通过自有渠道资源在 <i>3个工作日</i> 内为您寻找品牌拓展联系方式</p>
            <p class="font12">注：1.提交“联系特权”需求<i>3个工作日</i>内,将在“联系特权管理”提醒最新的反馈结果；2.对约定时间内未能提供有效 拓展联系人或由客户自主取消的找寻需求，系统会返还已扣删除的1个特权量；3.品牌库在持续更新中，支持客户查看 历史发起但当时未反馈有联系方式的连锁品牌的联系方式，且<i>不扣除权益量</i></p>
          </div>
          <div class="btns">
            <span @click="addContact()">添加联系方式</span>
            <span
              class="add-user-privilege"
              @click="addFork()"
            >使用联系特权</span>
          </div>
        </template>
        <template v-if="isEmptyContact">
          <p>贵公司已将品牌联系方式清空</p>
          <div class="btns">
            <span @click="addContact()">添加联系方式</span>
          </div>
        </template>
      </div>
      <div
        class="amount"
        v-else-if="showInterestsType === 4"
      >
        <img
          src="@/assets/images/no_list_data.png"
          imgtype='smallgoods'
          alt=""
        >
        <p class="p1">非常抱歉，您剩余可用联系特权数已使用完</p>
        <p class="p4">如需额外购买联系特权权益包，请客服微信号：<span>win18578675071</span> </p>
        <div class="btns">
          <span @click="addContact()">添加联系方式</span>
          <span class="add-user-privilege grey">使用联系特权</span>
        </div>
      </div>
      <div
        class="amount"
        v-else-if="showInterestsType === 5"
      >
        <img
          src="@/assets/images/no_list_data.png"
          imgtype='smallgoods'
          alt=""
        >
        <p class="p1">正在为你找寻此品牌联系人，于{{lookEndTime}}反馈找寻结果</p>
        <p class="p-text"><em class="el-icon-warning-outline"></em> 联系特权反馈结果可在【管理中心-联系特权管理】中查看</p>
        <p
          class="btn"
          @click="addContact()"
        >
          <span>添加联系方式</span>
        </p>
      </div>
      <div
        class="amount"
        v-else-if="showInterestsType === 6"
      >
        <img
          src="@/assets/images/no_list_data.png"
          imgtype='smallgoods'
          alt=""
        >
        <p class="p1">非常抱歉，当前品牌联系方式正在建设中...</p>
        <p class="p-text"><em class="el-icon-warning-outline"></em>您所发起的品牌“联系特权”--联系方式找寻失败，后续赢商品牌库联系方式更新后您可以直接查看该品牌联系方式且<span>将不会扣除权益量</span></p>
      </div>
      <div
        class="amount"
        v-else
      >
        <img
          src="@/assets/images/no_list_data.png"
          imgtype='smallgoods'
          alt=""
        >
        <p class="p1">暂无联系方式</p>
        <div
          class="btn"
          @click="addContact()"
        >
          添加联系方式
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="isUserLink"
      width="100%"
    >
      <div class="message-wrap">
        <div class="message-box">
          <div class="message-head">
            <div class="message-title">
              温馨提示
              <button
                @click="hideMessage"
                type="button"
                class="message-headbtn"
              >
                <i class="el-message-box__close el-icon-close"></i>
              </button>
            </div>
          </div>
          <div class="message-content">
            <div class="content-message">
              <p class="message-tips">确定后将扣除1条联系特权</p>
              <p class="message-tips2">(请填写联系人姓名及手机号码,以便我们工作人员第一时间将结果反馈给到您)</p>
            </div>
            <div class="message-input">
              <div class="input1">
                <input
                  type="text"
                  class="userName"
                  autocomplete="on"
                  v-model="username"
                  placeholder="请填写联系人姓名"
                >
              </div>
              <div class="input2">
                <input
                  type="text"
                  class="tel"
                  v-model="tel"
                  autocomplete="on"
                  @input="getTelNum"
                  placeholder="请填写手机号码"
                >
                <p
                  v-show="isTel"
                  style="text-align:left;margin-left: 67px;color: #ff0000;"
                >请填写正确的手机号码</p>
              </div>
            </div>
          </div>
          <div class="message-btns">
            <button
              @click="hideMessage"
              type="button"
              class="el-button el-button--default"
            >
              <span>取消</span>
            </button>
            <button
              @click="addForkBtn"
              type="button"
              class="el-button el-button--default el-button--primary "
            >
              <span>确定</span>
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
    <Model
      v-if="deleteContact"
      @Errorshow='Errorshow'
      :currentContact='currentContact'
    ></Model>
  </div>
</template>

<script>
import api from '@/api'
import Model from '@/views/brandDetail/components/popup.vue'
import { validateTel } from '@/utils/index'
export default {
  name: 'contactWay',
  data () {
    return {
      loading: true,
      area: '全部区域',
      brandId: '',
      gbid: '',
      projectArea: [],
      contactList: [],
      currentContact: {},
      deleteContact: false,
      interestsNum: 0,
      showInterestsType: null,
      isUserLink: false,
      isTel: false,
      username: '',
      tel: '',
      brandName: '',
      isHasContact: false,
      isEmptyContact: true
    }
  },
  props: {
    details: {
      type: Object
    },
    lookNumber: {
      type: Number
    },
    interestsData: {
      type: Object
    }
  },
  components: {
    Model
  },
  mounted () {
    this.brandId = this.$route.query.brandId
    this.gbid = this.$route.query.gbid
    this.brandName = this.details.brandName
    this.getDataContactList()
  },
  methods: {
    getAreaListFunc () {
      const params = {
        gbid: this.gbid,
        pid: this.brandId,
        type: 2
      }
      this.axios.post(api.getLinkManRegions, params).then(res => {
        res.data.data.unshift(
          {
            regionName: '全部区域',
            id: '',
            value: ''
          }
        )
        this.projectArea = res.data.data.map((item, index) => {
          const tem = {
            value: item.regionID,
            label: item.regionName
          }
          return tem
        })
      })
    },
    handleArea (val) {
      this.getDataContactList(val)
    },
    getDataContactList (areaName) {
      if (areaName === '全部区域') {
        areaName = ''
      }
      const params = {
        gbid: this.gbid,
        pageNum: 1,
        pageSize: 60,
        pid: this.brandId,
        source: 1,
        type: 2,
        id: areaName
      }
      this.axios.post(api.getLinkMan, params).then(res => {
        // console.log(res)
        this.contactList = res.data.data
        this.loading = false
        if (this.contactList.length === 0) {
          this.isHasContact = true
        } else {
          this.getAreaListFunc()
          this.isHasContact = false
        }
      })
    },
    getUserRightV4 () {
      this.axios.post(api.getLinkForkNumV4, { brandId: this.brandId })
        .then((res) => {
          // console.log(res.data)
          this.interestsNum = res.data.data.totalNum
          // 1. 无联系方式、无联系特权权益状态
          if (this.contactList && this.contactList.length === 0 && this.interestsNum === 0) {
            this.showInterestsType = 1
          }
          // 2. 无联系方式、有联系特权权益、当前品牌为非“全国连锁品牌”或 “区域连锁”状态
          if (this.contactList && this.contactList.length === 0 && this.interestsNum > 0 && this.details.countryChain === 0 && this.details.provinceChain === 0) {
            this.showInterestsType = 2
          }
          // 3. 无联系方式、有联系特权权益、当前品牌为“全国连锁品牌”或 “区域连锁”状态
          if (this.contactList && this.contactList.length === 0 && this.interestsNum > 0 && (this.details.countryChain === 1 || this.details.provinceChain === 1)) {
            this.showInterestsType = 3
            this.getIsCheckLinkMan().then((res) => {
              if (res.data.data === 1) {
                this.isEmptyContact = true
              } else {
                this.isEmptyContact = false
              }
            })
          }
          // 4. 联系特权数已使用完
          if (this.interestsData.totallook4link > 0 && this.interestsNum === 0) {
            this.showInterestsType = 4
          }
          // 5. 正在查找联系人
          if (res.data.data.isLookedBrand) {
            this.lookEndTime = res.data.data.lookEndTime
            this.showInterestsType = 5
          }
          // 6. 找寻失败
          if (res.data.data.lookStatus === 41) {
            this.showInterestsType = 6
          }
          // console.log(this.showInterestsType)
        })
    },
    lookBrandDetail () {
      this.axios.post(api.getBrandLookRightDeduction,
        {
          brand_id: this.brandId,
          source: 1,
          gbid: this.gbid
        }
      )
        .then((res) => {
          if (res.data.data === 1) {
            this.$emit('updateMarker', true)
            this.$emit('updateNumber')
          }
        })
    },
    addFork () {
      if (this.interestsData.totallook4link === 0) {
        this.$message({
          type: 'warning',
          message: '子账号暂无权限使用联系特权，请联系回集团总账号'
        })
        return false
      } else if (this.interestsData.look4link > 0) {
        this.isUserLink = true
      }
      // if (this.user.version === '2.0' && this.user.isadmin !== 0 && this.user.operateIds.indexOf(34) === -1) {
      //   this.$message({
      //     type: 'warning',
      //     message: '暂无权限访问此页，如需开通权限，请联系管理员在“管理中心-成员管理”开通！'
      //   })
      //   return false
      // } else {
      //   if (this.user.opIds.indexOf(2) > -1) {
      //     this.$message({
      //       type: 'warning',
      //       message: '子账号暂无权限使用联系特权,请联系回集团总账号'
      //     })
      //     return
      //   } else {
      //     this.isUserLink = true
      //   }
      // }
    },
    addForkBtn () {
      if (this.username === '') {
        this.$message({
          type: 'warning',
          message: '请输入您的姓名！'
        })
        return
      } else if (this.username.length > 20) {
        this.$message({
          type: 'warning',
          message: '联系人姓名过长，请重新输入！'
        })
        return
      }
      if (this.tel === '') {
        this.$message({
          type: 'warning',
          message: '请输入您的联系方式！'
        })
        return
      }
      if (this.tel.length > 11) {
        this.$message({
          type: 'warning',
          message: '请输入正确的联系方式！'
        })
        return
      }
      this.axios.post(api.addLookForLink, { brandId: this.brandId, userName: this.username, userTel: this.tel })
        .then((res) => {
          if (res.data.code === 0) {
            this.getUserRightV4()
            this.isUserLink = false
            this.$message({
              type: 'success',
              message: '提交成功！'
            })
          } else {
            this.isUserLink = false
          }
        })
    },
    getTelNum () {
      if (validateTel(this.tel)) {
        this.isTel = true
      } else {
        this.isTel = false
      }
    },
    hideMessage () {
      this.isUserLink = false
    },
    removeContact (item) {
      this.currentContact = item
      this.deleteContact = true
      document.body.style.overflowY = 'hidden'
    },
    Errorshow (type) {
      if (type) {
        this.getDataContactList('')
      }
      this.deleteContact = !this.deleteContact
      document.body.style.overflowY = 'auto'
    },
    addContact () {
      localStorage.setItem('brandId', this.brandId)
      localStorage.setItem('gbid', this.gbid)
      localStorage.setItem('brandName', this.brandName)
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8030'
      }
      window.open(temUrl + '/brand/#/issue/addContact?brandId=' + this.$route.query.brandId + '&gbid=' + this.$route.query.gbid + '&brandName=' + encodeURIComponent(encodeURIComponent(this.brandName)) + '&from=enterprise', '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.contactWay
  .simpleInfoPanel
    box-sizing border-box
    width 100%
    padding 0 24px
    border-radius 0 0 3px 3px
    .simpleBorderPanel
      line-height 50px
      text-indent 8px
      font-size 12px
      margin-top 27px
      color #fff
      .icon-prompt
        vertical-align middle
      p
        margin 2px 17px 0 0
    .contactCards
      margin 32px -10px 40px 0
      overflow hidden
      li
        width 19%
        float left
        margin 0 12px 12px 0
        height 248px
        background rgba(255, 255, 255, 0.1)
        position relative
        padding 16px
        box-sizing border-box
        color #fff
        span.openMode
          background rgba(18, 166, 67, 0.5)
          display inline-block
          width 48px
          height 27px
          line-height 27px
          text-align center
          font-size 14px
          position absolute
          right 0
          top 0
        div
          line-height 26px
          font-weight bold
          font-size 14px
        p.line
          height 1px
          width 100%
          background rgba(255, 255, 255, 0.2)
          margin 12px 0 19px 0
        p
          line-height 24px
          font-size 14px
        i.el-icon-delete
          position absolute
          right 15px
          bottom 15px
          cursor pointer
        .btnComonPlus
          width 98px
          height 28px
          margin 8px 0 0 0
    .w-title-gradients
      margin 24px 0 0 -24px
    .guessUlikes
      margin 38px -8px 0 0
.amount-text
  position relative
  color #ffffff
  font-size 18px
  margin-left 40px
  font-weight 500
  margin-top 10px
  i
    position absolute
    left -20px
    top 1.5px
    width 2px
    height 18px
    background #FFA234
  span
    color #FFA134
.contact-privilege
  background-color #272930
  padding 20px 0 20px 0
  .amount
    padding 70px 0px
    text-align center
    .p1
      margin-top 15px
      margin-bottom 15px
      font-size 14px
    img
      width 136px
      height 98px
    p
      color #fff
      i
        display inline-block
        color #ffa134
        padding-bottom 3px
    .btn
      display inline-block
      width 114px
      height 26px
      border-radius 3px
      font-size 14px
      line-height 26px
      text-align center
      cursor pointer
      color #FFA134
      border 1px solid #FFA134
    em
      margin-right 5px
  .tip-text
    width 560px
    margin auto
    padding 14px
    border 1px dashed rgba(255, 255, 255, 0.6)
    background-color rgba(255, 255, 255, 0.1)
    text-align left
    color #fff
    .font13
      font-size 13px
    .font12
      font-size 12px
  .btns
    margin-top 20px
    span
      display inline-block
      width 114px
      height 26px
      border 1px solid #FFA134
      border-radius 3px
      color #FFA134
      font-size 14px
      line-height 26px
      text-align center
      margin 0 10px
      cursor pointer
      &.add-user-privilege
        color #ffffff
        background-color #FFA134
      &.grey
        background-color #999
        border-color #999
        cursor not-allowed
  .p-text
    width 430px
    margin auto
    padding 10px 12px
    border 1px dashed rgba(255, 255, 255, 0.6)
    background-color rgba(255, 255, 255, 0.1)
    margin-bottom 15px
    font-size 14px
.message-wrap
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  text-align center
  z-index 2000
  .message-box
    display inline-block
    vertical-align middle
    width 500px
    background #fff
    border-radius 3px
    font-size 16px
    overflow hidden
    backface-visibility hidden
    .message-head
      position relative
      padding 20px 20px 0
      .message-title
        text-align center
        margin-bottom 0
        font-size 16px
        font-weight 700
        height 18px
        color #333
      .message-headbtn
        position absolute
        top 19px
        right 20px
        background 0 0
        border none
        outline 0
        padding 0
        cursor pointer
        .el-message-box__close
          color #999
          &:hover
            color #FFA134
    .message-content
      padding 30px 10px
      color #48576a
      font-size 14px
      position relative
      .content-message
        margin 0
        line-height 1.4
        .message-tips2
          color #666
      .message-input
        margin 10px 0
        input
          width 70%
          height 34px
          border 1px solid rgba(0, 0, 0, 0.2)
          margin 10px 0
          padding-left 10px
          border-radius 3px
    .message-btns
      padding-bottom 30px
      .el-button
        padding 10px 60px
.message-wrap::after
  content ''
  display inline-block
  height 100%
  width 0
  vertical-align middle
</style>
