<template>
  <div class="dataAnalysis">
    <div
      class="simpleInfoPanel"
      style="border-radius:0 0 3px 3px"
    >
      <div
        v-show="isShowBrandDetail"
        class="analysis-wrap-tips"
      >
        <div
          v-if="lookNumber > 0"
          class="look-detail-wrap"
        >
          <div class="text">
            <span class="icon-small-tip"></span>
            您还可查看 <i>{{lookNumber}}</i> 个品牌的 “开关店分布 + 数据分析 + 拓展需求 + 联系方式”数据
          </div>
          <div class="btn">
            <span @click="lookBrandDetail()">查看数据分析</span>
          </div>
        </div>
        <div
          v-else-if="interestsData.totallookbrandrightnum > 0"
          class="look-detail-wrap"
        >
          <div class="text">
            <span class="icon-small-tip"></span>
            您还可查看 <i>{{lookNumber}}</i> 个品牌的 “开关店分布 + 数据分析 + 拓展需求 + 联系方式”数据
          </div>
        </div>
        <div
          v-else
          class="noData-look-brand"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，您无查看权限。</p>
        </div>
      </div>
      <div v-show="isShowBrandContent">
        <div
          class="districtNav"
          v-if="false"
        >
          <!-- 后端无法实现，当前版本隐藏2020年12月18日15:29:19 -->
          地域范围
          <el-radio-group
            @change="changeArea"
            v-model="district"
            size="medium"
          >
            <el-radio-button :label="0">按区域</el-radio-button>
            <el-radio-button :label="1">按省份</el-radio-button>
            <el-radio-button :label="2">按线级</el-radio-button>
            <el-radio-button :label="3">按城市</el-radio-button>
          </el-radio-group>
          <div
            class="subMenuPanel"
            :class="{'showLess':!isShowMoreMenu&&district===3}"
          >
            <span
              v-for="item in subMenu[district]"
              :key="item.value"
              :class="{'activeItem': item.value===areaValue}"
              @click="checkItem(item.value)"
            >
              {{item.typeName}}
            </span>
          </div>
          <span
            class="fr showMore"
            v-if="district===3"
            @click="isShowMoreMenu=!isShowMoreMenu"
          >{{isShowMoreMenu?'收起':'展开全部'}}</span>
        </div>
        <h3
          v-show="hasDataStoreDistribution"
          class="w-title-gradients mt25"
        >品牌开关店趋势</h3>
        <div
          v-show="hasDataStoreDistribution"
          class="chartsLine"
          id="storeAreaCharts"
        ></div>
        <!-- <div class="details-no-data" v-show="!hasDataStoreDistribution">
          <img src="@/assets/images/public/not-plandata.png" alt="">
          <p>抱歉，暂无数据</p>
        </div> -->
        <div class="clearfix"></div>
        <h3 class="w-title-gradients mt40">入驻城市商圈分析
          <el-tooltip
            class="item"
            popper-class="planTooltip"
            effect="dark"
            placement="bottom"
          >
            <span class="el-icon-question"></span>
            <div slot="content">商圈划分说明：<br />
              1、商圈能级类型（市级/区域）：商圈可辐射区域及消费者的范围等级，依据商圈可辐射区域及消费者的范围判断；<br />
              2、商圈发展类型（成熟/新兴）：商圈存在的时长级别；<br />
              成熟商圈：随着时间积累商业发展已相对完善的商圈；<br />
              新兴商圈：近年来伴随城市商业发展新形成的商圈。</div>
          </el-tooltip>
        </h3>
        <div class="simpleWrap">
          <div class="fl cardbox">
            <h3>按功能划分商圈</h3>
            <div
              class="chartsPie"
              id="pieFunction"
              v-show="hasDataPieFunction"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieFunction"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
            <div
              class="text"
              v-show="hasDataPieFunction"
            >
              <i class="icon-contrast"></i>
              同类品牌入驻功能商圈偏好：{{pieText.pieFunction}}
            </div>
          </div>
          <div class="fr cardbox">
            <h3>按发展划分商圈</h3>
            <div
              class="chartsPie"
              id="pieDevelop"
              v-show="hasDataPieDevelop"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieDevelop"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
            <div
              class="text"
              v-show="hasDataPieDevelop"
            >
              <i class="icon-contrast"></i>
              同类品牌入驻发展商圈偏好：{{pieText.pieDevelop}}
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <h3
          v-show="hasDataProductLine"
          class="w-title-gradients mt40"
        >入驻产品线分析</h3>
        <div
          v-show="hasDataProductLine"
          class="chartsBar"
          id="barProductLine"
        ></div>
        <!-- <div class="details-no-data" v-show="!hasDataProductLine" >
          <img src="@/assets/images/public/not-plandata.png" alt="">
          <p>抱歉，暂无数据</p>
        </div> -->
        <h3 class="w-title-gradients mt40">入驻购物中心分析</h3>
        <div class="simpleWrap">
          <div class="fl cardbox">
            <h3>购物中心档次偏好</h3>
            <div
              class="chartsPie"
              id="pieLevel"
              v-show="hasDataPieLevel"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieLevel"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
            <div
              class="text"
              v-show="hasDataPieLevel"
            >
              <i class="icon-contrast"></i>
              同类品牌档次偏好：{{pieText.pieLevel}}
            </div>
          </div>
          <div class="fr cardbox">
            <h3>购物中心体量偏好</h3>
            <div
              class="chartsPie"
              id="pieVolumes"
              v-show="hasDataPieVolumes"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieVolumes"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
            <div
              class="text"
              v-show="hasDataPieVolumes"
            >
              <i class="icon-contrast"></i>
              同类品牌体量偏好：{{pieText.pieVolumes}}
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="simpleWrap">
          <div class="fl cardbox">
            <h3>购物中心开业年限偏好</h3>
            <div
              class="chartsPie"
              id="pieYears"
              v-show="hasDataPieYears"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieYears"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
            <div
              class="text"
              v-show="hasDataPieYears"
            >
              <i class="icon-contrast"></i>
              同类品牌购物中心开业年限偏好：{{pieText.pieYears}}
            </div>
          </div>
          <div class="fr cardbox">
            <h3>购物中心楼层偏好</h3>
            <div
              class="chartsPie"
              id="pieFloors"
              v-show="hasDataPieFloors"
            ></div>
            <div
              class="jurisdiction-wrap-no-data"
              v-if="!hasDataPieFloors"
            >
              <img
                src="@/assets/images/no_list_data.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
            <div
              class="text"
              v-show="hasDataPieFloors"
            >
              <i class="icon-contrast"></i>
              同类品牌楼层偏好：{{pieText.pieFloors}}
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <h3 class="w-title-gradients mt40 longTitle">同楼层开店关联<span>（注：当品牌开店≥2家时显示此数据内容）</span></h3>
        <el-select
          popper-class="black-select-panel"
          class="chartsFiltrate fr"
          v-model="volumesNum"
          placeholder="请选择"
          @change="getDataFloorFormat"
        >
          <el-option
            v-for="item in volumesOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="clearfix"></div>
        <div class="simpleWrap mg-0-auto">
          <div class="fl cardbox">
            <h3>同楼层关联业态TOP10</h3>
            <div
              class="chartsBar"
              id="barFormat"
              v-show="hasDataFormatTop10"
            ></div>
            <div
              v-if="!hasDataFormatTop10"
              class="details-no-data"
              v-show="!hasDataStoreDistribution"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
          <div class="fr cardbox">
            <h3>同楼层关联品牌TOP10</h3>
            <div
              class="brand-ranking-box"
              v-loading="top10Loading"
              background="none"
              element-loading-text="加载中..."
            >
              <div
                class="brand-ranking"
                v-if="brandTopList && brandTopList.length > 0"
              >
                <ul>
                  <li class="menu">
                    <span>排序</span>
                    <span>品牌名称</span>
                    <span>品牌业态</span>
                  </li>
                  <li
                    v-for="(item, index) in brandTopList"
                    :key="index"
                  >
                    <span v-if="index < 3"><i :class="'brand'+ index"></i></span>
                    <span v-else>{{index + 1}}<i :class="'brand'+ index"></i></span>
                    <span @click="toDetails(item.brandId, 0, item.gbid)">{{item.brandName}}</span>
                    <span>{{item.industry}}</span>
                  </li>
                </ul>
              </div>
              <div
                v-else
                class="details-no-data"
                v-show="!hasDataStoreDistribution"
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <template v-if="hasDataCompeting">
          <h3 class="w-title-gradients mt40">近一年竞品开店对比</h3>
          <el-select
            popper-class="black-select-panel"
            class="chartsFiltrate fr"
            v-model="storeValue"
            placeholder="请选择"
            @change="changeStore"
          >
            <el-option
              v-for="item in storeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="clearfix"></div>
          <div
            class="chartsLine"
            v-loading="storeLoading"
            id="lineCompetitor"
          ></div>
        </template>
        <div class="clearfix mt40"></div>
        <div
          class="cityList"
          v-if="tipStr!==''"
        >
          <i class="el-icon-warning"></i>
          <div v-html="tipStr"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import api from '@/api'
export default {
  name: 'dataAnalysis',
  data () {
    return {
      isShowBrandDetail: false,
      isShowBrandContent: false,
      district: 0,
      subMenu: [],
      brandId: '',
      gbid: '',
      source: '',
      areaValue: '',
      params: {
        brand_id: '',
        gbid: '',
        source: '',
        cityID: '',
        cityShangYeLevel: '',
        provinceID: '',
        regionID: ''
      },
      pieText: {
        pieFunction: '', // 按功能划分商圈
        pieDevelop: '',
        pieLevel: '',
        pieVolumes: '',
        pieYears: '',
        pieFloors: ''
      },
      hasDataPieFunction: true,
      hasDataPieDevelop: true,
      hasDataPieLevel: true,
      hasDataPieVolumes: true,
      hasDataPieYears: true,
      hasDataPieFloors: true,
      hasDataFormatTop10: true,
      hasDataBrandTop10: true,
      volumesNum: 0,
      volumesOptions: [],
      brandTopList: [],
      top10Loading: true,
      storeLoading: true,
      storeOptions: [
        // { // 产品需求变更，不显示在营门店
        //   label: '在营门店数量',
        //   value: 0
        // },
        {
          label: '新开店数量',
          value: 6
        },
        {
          label: '新关店数量',
          value: 1
        }
      ],
      storeValue: 6,
      hasDataStoreDistribution: true,
      hasDataCompeting: true,
      hasDataProductLine: true,
      storeAreaCharts: {},
      lineCompetitor: {},
      isShowMoreMenu: false
    }
  },
  computed: {
  },
  components: {
  },
  mounted () {
    this.brandId = this.$route.query.id
    this.gbid = this.$route.query.gbid
    this.source = this.$route.query.source
    this.axios.post(api.getBrandDetailIsShow,
      {
        brand_id: this.$route.query.id,
        source: this.$route.query.source,
        gbid: this.$route.query.gbid
      }
    )
      .then((res) => {
        if (res.data.data) {
          this.checkItem('')
          this.getDataArea()
          this.getDataVolumes() // 同楼层开店关联筛选项
          this.getDataFloorFormat() // 同楼层开店关联业态TOP10
          this.isShowBrandContent = true
          this.isShowBrandDetail = false
        } else {
          this.isShowBrandContent = false
          this.isShowBrandDetail = true
        }
      })
    this.storeAreaCharts = echarts.init(document.getElementById('storeAreaCharts'))
    this.lineCompetitor = echarts.init(document.getElementById('lineCompetitor'))
  },
  props: {
    lookNumber: {
      type: Number
    },
    interestsData: {
      type: Object
    },
    tipStr: {
      type: String
    }
  },
  methods: {
    toDetails (id, questionState, gbid) {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=0&questionState=' + questionState + '&gbid=' + gbid, '_blank')
    },
    isNull (item) {
      let res = '--'
      if (item.indexOf('null') === -1 && item) {
        res = item
      }
      return res
    },
    getDataPreference (params) {
      this.axios.post(api.getRefer, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.pieText.pieFunction = this.isNull(resData.functionSq.referVal) + ' ' + this.isNull(resData.functionSq.referAvgStr)
          this.pieText.pieDevelop = this.isNull(resData.expandSq.referVal) + ' ' + this.isNull(resData.expandSq.referAvgStr)
          this.pieText.pieLevel = this.isNull(resData.level.referVal) + ' ' + this.isNull(resData.level.referAvgStr)
          this.pieText.pieVolumes = this.isNull(resData.area.referVal) + ' ' + this.isNull(resData.area.referAvgStr)
          this.pieText.pieYears = this.isNull(resData.year.referVal) + ' ' + this.isNull(resData.year.referAvgStr)
          this.pieText.pieFloors = this.isNull(resData.floor.referVal) + ' ' + this.isNull(resData.floor.referAvgStr)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    lookBrandDetail () {
      this.axios.post(api.getBrandLookRightDeduction,
        {
          brand_id: this.$route.query.id,
          source: this.$route.query.source,
          gbid: this.$route.query.gbid
        }
      )
        .then((res) => {
          if (res.data.data === 1) {
            this.isShowBrandDetail = false
            this.isShowBrandContent = true
            this.checkItem('')
            this.getDataArea()
            this.getDataVolumes() // 同楼层开店关联筛选项
            this.getDataFloorFormat() // 同楼层开店关联业态TOP10
            this.$emit('updateMarker', true)
          }
        })
    },
    checkItem (value) {
      this.areaValue = value
      this.params.brand_id = this.brandId
      this.params.gbid = this.gbid
      this.params.source = this.source
      this.params.state = this.storeValue
      switch (this.district) {
        case 0:
          this.params.regionID = this.areaValue
          break
        case 1:
          this.params.provinceID = this.areaValue
          break
        case 2:
          this.params.cityShangYeLevel = this.areaValue
          break
        case 3:
          this.params.cityID = this.areaValue
          break
        default:
          break
      }
      this.getDataStoreDistribution(this.params)
      this.getDataCityBrand(this.params)
      this.getDataProductLine(this.params)
      this.getDataPlaza(this.params)
      this.getDataCompetitor(this.params)
      this.getDataPreference(this.params) // 同品类品牌
    },
    changeArea (val) {
      this.checkItem('')
    },
    getDataArea () {
      const arr = ['所有区域', '所有省份', '所有线级', '所有城市']
      this.axios.post(api.getBrandAnalysisScreeningConditions, {
      }).then(res => {
        if (res.data.code === 0) {
          this.subMenu = res.data.data.map((item, index) => {
            item.unshift({
              typeName: arr[index],
              value: ''
            })
            return item
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    changeStore (val) {
      this.params.state = val
      this.getDataCompetitor(this.params)
    },
    getDataStoreDistribution (params) {
      this.axios.post(api.getBrandOpenShopTrend, params).then(res => {
        if (res.data.code === 0) {
          let value = 0
          const resData = res.data.data
          const xData = []
          const yData = []
          const xArr = ['新开店量', '新关店量', '在营门店'] // 6开店，1关店，10在营
          for (let i = 0; i < resData.length; i++) {
            const element = resData[i].reverse()
            const tempArrX = []
            const tempArrY = []
            for (let j = 0; j < element.length; j++) {
              const element1 = element[j]
              value += element1.openOrCloseNum
              tempArrY.push(element1.openOrCloseNum)
              tempArrX.push(element1.date)
            }
            yData.push({ name: xArr[i], data: tempArrY })
            xData.push(tempArrX)
          }
          const option = this.createChartsLine([yData.reverse(), xData[0]])
          this.storeAreaCharts.setOption(option)
          if (value) {
            this.hasDataStoreDistribution = true
          } else {
            this.hasDataStoreDistribution = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    createChartsLine ([yData, xData]) {
      const lengendData = []
      const series = []
      const colors = [
        { color1: 'rgba(255, 161, 52, 0.6)', color2: 'rgba(255, 161, 52, 0.01)' },
        { color1: 'rgba(55, 172, 255, 0.6)', color2: 'rgba(55, 172, 255, 0.01)' },
        { color1: ' rgba(22, 218, 100, 0.6)', color2: ' rgba(22, 218, 100, 0.01)' },
        { color1: 'rgba(199, 80, 255, 0.6)', color2: 'rgba(199, 80, 255, 0.01)' },
        { color1: 'rgba(229, 64, 40, 0.6)', color2: 'rgba(229, 64, 40, 0.01)' },
        { color1: 'rgba(255, 252, 0, 0.6)', color2: 'rgba(255, 252, 0, 0.01)' },
        { color1: ' rgba(11, 187, 193, 0.6)', color2: ' rgba(11, 187, 193, 0.01)' },
        { color1: 'rgba(54, 78, 225, 0.6)', color2: 'rgba(54, 78, 225, 0.01)' },
        { color1: 'rgba(0,0,0,1)', color2: 'rgba(0,0,0,1)' },
        { color1: 'rgba(0,0,0,1)', color2: 'rgba(0,0,0,1)' }
      ]
      for (let i = 0; i < yData.length; i++) {
        const ele = yData[i]
        lengendData.push(ele.name)
        const obj = {
          name: ele.name,
          type: 'line',
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: colors[i].color1
              }, {
                offset: 0.8,
                color: colors[i].color2
              }], false),
              shadowColor: 'rgba(0, 0, 0, 0.1)',
              shadowBlur: 10
            }
          },
          data: ele.data
        }
        series.push(obj)
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.3)'
        },
        color: [
          'rgba(255, 161, 52, 0.8)',
          'rgba(55, 172, 255, 0.8)',
          'rgba(22, 218, 100, 0.8)',
          'rgba(199, 80, 255, 0.8)',
          'rgba(229, 64, 40, 0.8)',
          'rgba(255, 252, 0, 0.8)',
          'rgba(11, 187, 193, 0.8)',
          'rgba(54, 78, 225, 0.8)'
        ],
        legend: {
          data: lengendData,
          textStyle: {
            color: '#ffffff'
          },
          bottom: -5,
          right: 'center',
          color: [
            'rgba(255, 161, 52, 0.8)',
            'rgba(55, 172, 255, 0.8)',
            'rgba(22, 218, 100, 0.8)',
            'rgba(199, 80, 255, 0.8)',
            'rgba(229, 64, 40, 0.8)',
            'rgba(255, 252, 0, 0.8)',
            'rgba(11, 187, 193, 0.8)',
            'rgba(54, 78, 225, 0.8)'
          ],
          icon: 'rect',
          itemHeight: 2,
          itemWidth: 12,
          itemGap: 24
        },
        grid: {
          top: '10%',
          left: '5%',
          right: '7%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xData,
            axisLine: {
              lineStyle: {
                color: 'rgba(226,226,226,.1)'
              }
            },
            axisLabel: {
              color: '#ffffff',
              fontSize: 12
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#ffffff',
              fontSize: 12
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }
        ],
        series: series
      }
      return option
    },
    getDataCityBrand (params) {
      this.axios.post(api.getAnalysisOfCityFunctionBusinessDistrict, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieFunction = true
          const resData = res.data.data
          const { dataArr, maxItem } = this.pieHandleData(resData)
          this.createChartsPie(dataArr, 'pieFunction', maxItem)
        } else {
          this.hasDataPieFunction = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getAnalysisOfCityExpandBusinessDistrict, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieDevelop = true
          const resData = res.data.data
          const { dataArr, maxItem } = this.pieHandleData(resData)
          this.createChartsPie(dataArr, 'pieDevelop', maxItem)
        } else {
          this.hasDataPieDevelop = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProductLine (params) {
      this.axios.post(api.getBrandAnalysisEnterProjectsNum, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            this.hasDataProductLine = true
          } else {
            this.hasDataProductLine = false
          }
          const yData = []
          const xData = []
          for (let i = 0; i < resData.length; i++) {
            const element = resData[i]
            yData.push(element.value)
            xData.push(element.typeName)
          }
          this.createChartsBar([yData, xData], 'barProductLine', { tooltip: { formatter: '品牌入驻 {b} 的在营门店量：{c}' }, yAxis: [{ axisLabel: { formatter: '{value}' } }] })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataPlaza (params) {
      this.axios.post(api.getBrandShoppingCenterLevelPreference, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieLevel = true
          const resData = res.data.data
          let count = 0
          for (let i = 0; i < resData.length; i++) {
            count += resData[i].counts
          }
          if (count === 0) {
            this.hasDataPieLevel = false
          } else {
            const { dataArr, maxItem } = this.pieHandleData(resData)
            this.createChartsPie(dataArr, 'pieLevel', maxItem)
          }
        } else {
          this.hasDataPieLevel = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getBrandChartAnalysis, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data.brandgouwuguimo.map(item => {
            item.name = item.mianjiname
            return item
          })
          if (resData.length) {
            this.hasDataPieVolumes = true
          } else {
            this.hasDataPieVolumes = false
          }
          const resData1 = res.data.data.brandgouwufloor.map(item => {
            item.name = item.floorName
            return item
          })
          if (resData1.length) {
            this.hasDataPieFloors = true
          } else {
            this.hasDataPieFloors = false
          }
          const { dataArr, maxItem } = this.pieHandleData(resData)
          this.createChartsPie(dataArr, 'pieVolumes', maxItem)
          const pieObj = this.pieHandleData(resData1)
          this.createChartsPie(pieObj.dataArr, 'pieFloors', pieObj.maxItem)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.post(api.getBrandProjectYear, params).then(res => {
        if (res.data.code === 0 && res.data.data.length) {
          this.hasDataPieYears = true
          const resData = res.data.data
          const { dataArr, maxItem } = this.pieHandleData(resData)
          this.createChartsPie(dataArr, 'pieYears', maxItem)
        } else {
          this.hasDataPieYears = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataFloorFormat () {
      const params = {
        brandId: this.brandId,
        gbid: this.gbid,
        areaId: this.volumesNum,
        levelId: ''
      }
      this.axios.post(api.getBrandIndustryTop10, params).then(res => {
        const extraOption = {
          xAxis: {
            axisLabel: {
              rotate: 45
            }
          }
        }
        this.top10Loading = true
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.industryTop10 && resData.industryTop10.length > 0) {
            const yData = []
            const xData = []
            for (let i = 0; i < resData.industryTop10.length; i++) {
              const element = resData.industryTop10[i]
              xData.push(element.name)
              yData.push(element.percents)
            }
            this.createChartsBar([yData, xData], 'barFormat', extraOption)
          } else {
            this.hasDataFormatTop10 = false
          }
          if (resData.brandTop10 && resData.brandTop10.length > 0) {
            this.brandTopList = resData.brandTop10
          } else {
            this.brandTopList = []
            this.hasDataBrandTop10 = false
          }
          this.top10Loading = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataVolumes () {
      this.axios.post(api.listFloorArea, { brandId: this.brandId }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.volumesOptions = resData.map(item => {
            const tempObj = {
              label: item.areaName,
              value: item.areaId
            }
            return tempObj
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataCompetitor (params) {
      const filterArr = []
      // filterArr[0] = '在营门店数量'
      filterArr[6] = '新开店数量'
      filterArr[1] = '新关店数量' // 后端要这么返回，页面所有该内容都这么处理
      this.storeLoading = true
      this.axios.post(api.getBrandAnalysisCompetitionBrandBusinessNum, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length > 1) {
            let value = 0
            const yData = []
            const xData = []
            for (let index = 0; index < resData.length; index++) {
              const element = resData[index]
              for (let i = 0; i < element.businessNum.length; i++) {
                value += element.businessNum[i]
              }
              const tempObj = {
                name: element.brandName,
                data: element.businessNum.reverse()
              }
              yData.push(tempObj)
              xData.push(element.date.reverse())
            }
            if (value) {
              this.hasDataCompeting = true
              const option = this.createChartsLine([yData, xData[0]])
              this.lineCompetitor.setOption(option)
              const extraOption = {
                tooltip: {
                  trigger: 'axis',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  formatter: (params, ticket, callback) => {
                    let text = params[0].axisValue + filterArr[this.storeValue] + '：<br>'
                    for (let i = 0; i < params.length; i++) {
                      const ele = params[i]
                      text += ele.marker + ele.seriesName + '：' + ele.value + '<br>'
                    }
                    return text
                  }
                }
              }
              this.lineCompetitor.setOption(extraOption)
            } else {
              this.hasDataCompeting = false
            }
          } else {
            this.hasDataCompeting = false
          }
          this.storeLoading = false
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    pieHandleData (resData) {
      let maxItem = {
        name: resData[0].name,
        percents: resData[0].percents
      }
      const dataArr = resData.map(item => {
        const tempObj = {
          name: item.name,
          value: item.counts,
          percents: item.percents
        }
        if (maxItem.percents < item.percents) {
          maxItem = item
        }
        return tempObj
      })
      return { dataArr, maxItem }
    },
    createChartsPie (data, container, maxItem) {
      const legendName = []
      for (let i = 0; i < data.length; i++) {
        const ele = data[i]
        legendName.push(ele.name)
      }
      const charts = echarts.init(document.getElementById(container))
      const option = {
        title: {
          zlevel: 0,
          text: maxItem.percents + '%',
          top: 'middle',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 35
          }
        },
        graphic: {
          type: 'text',
          zlevel: 0,
          top: '55%',
          left: 'center',
          style: {
            text: maxItem.name,
            fill: '#fff',
            fontSize: 16
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          // orient: 'vertical',
          x: 'center',
          bottom: -5,
          data: legendName,
          color: '#fff',
          icon: 'square',
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '60%'],
            avoidLabelOverlap: false,
            zlevel: 1,
            hoverOffset: 11,
            label: { //  饼图图形上的文本标签
              normal: { // normal 是图形在默认状态下的样式
                show: false,
                position: 'center',
                fontSize: 16,
                fontWeight: 'bold'
                // formatter: '{c}' // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
              }
            },
            itemStyle: {
              normal: {
                shadowBlur: 50,
                shadowColor: '#444'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: data
          }
        ],
        color: ['#0FC967', '#37ACFF', '#166EE9', 'rgb(131,175,155)', '#FF69B4', '#FF0000', '#00F5FF']
      }
      if (
        container === 'pieFunction'
      ) {
        option.legend.data = ['市级商圈', '区域商圈', '非商圈区域']
      } else if (container === 'pieDevelop') {
        option.legend.data = ['成熟商圈', '新兴商圈', '非商圈区域']
      }
      charts.setOption(option)
      charts.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        name: maxItem.name
      })
    },
    createChartsBar ([yData, xData], container, extraOption) {
      const charts = echarts.init(document.getElementById(container))
      const option = {
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              interval: 0,
              color: '#fff',
              formatter: '{value}%'
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(226,226,226,0.1)'
              }
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '30%',
            label: {
              show: container === 'barFormat',
              position: 'top',
              color: '#fff',
              formatter: '{c}%'
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#1dcbec' }, // 柱图渐变色
                    { offset: 1, color: '#41cce6' } // 柱图渐变色
                  ]
                )
              }
            },
            data: yData
          }
        ]
      }
      charts.setOption(option)
      if (extraOption) {
        charts.setOption(extraOption)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.dataAnalysis
  color #fff
  .simpleInfoPanel
    width 100%
    .longTitle
      width auto
      background-size 232px 28px
      background-repeat no-repeat
      span
        font-size 12px
    .districtNav
      margin-bottom 36px
      padding 28px 0 0 16px
      span.showMore
        cursor pointer
        margin 10px 30px 0 0
      .el-radio-group
        margin-left 10px
      .subMenuPanel
        width 92%
        height auto
        background #1E1E23
        border-radius 3px
        margin-top 15px
        margin-left 78px
        padding-bottom 13px
        position relative
        &::after
          content ''
          width 8px
          height 8px
          display inline-block
          background #1E1E23
          position absolute
          transform rotate(45deg)
          left 30px
          top -4px
        span
          display inline-block
          margin 13px 15px 0 15px
          line-height 20px
          cursor pointer
          while-space nowrap
          &:hover
            text-decoration underline
        span.activeItem
          color #ff9d3f
          text-decoration underline
      .showLess
        height 65px
        overflow hidden
    .chartsLine
      width 1280px
      height 338px
    .chartsPie
      width 98%
      height 450px
    .chartsBar
      width 99%
      height 420px
    .simpleWrap
      width 97%
      margin 35px auto
      overflow hidden
    .mg-0-auto
      margin 0 auto
    .chartsFiltrate
      position relative
      bottom 30px
      right 20px
    .cardbox
      width 608px
      height 548px
      background #272930
      border 1px solid rgba(255, 255, 255, 0.1)
      box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
      border-radius 3px
      box-sizing border-box
      padding 24px
      position relative
      text-align center
      h3
        font-size 16px
        text-align left
      .text
        width auto
        padding-right 20px
        display inline-block
        height 26px
        line-height 26px
        background rgba(255, 205, 147, 0.2)
        border-radius 3px
        position relative
        bottom 60px
        .icon-contrast
          margin 0 3px 0 10px
.brand-ranking
  margin-top 20px
  ul
    li
      height 40px
      line-height 40px
      color #fff
      font-size 16px
      span:nth-child(1)
        display inline-block
        width 25%
        text-align center
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      span:nth-child(2)
        display inline-block
        width 32%
        text-align left
        cursor pointer
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      span:nth-child(3)
        display inline-block
        width 32%
        text-align left
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
.brand-ranking ul li:nth-child(odd)
  background rgba(255, 255, 255, 0.1)
.brand-ranking ul li.menu
  background rgba(255, 255, 255, 0.32)
.brand0
  display inline-block
  width 24px
  height 27px
  background url('~@/assets/images/brandDetail/1.png') no-repeat
  background-size 100%
  vertical-align middle
.brand1
  display inline-block
  width 24px
  height 27px
  background url('~@/assets/images/brandDetail/2.png') no-repeat
  background-size 100%
  vertical-align middle
.brand2
  display inline-block
  width 24px
  height 27px
  background url('~@/assets/images/brandDetail/3.png') no-repeat
  background-size 100%
  vertical-align middle
.analysis-wrap-tips
  height 400px
  background-color #282A30
  text-align center
.look-detail-wrap
  padding-top 120px
  .text
    width 530px
    height 38px
    line-height 38px
    font-size 14px
    margin auto
    background-color rgba(0, 0, 0, 0.1)
    color #fff
    i
      color #ffa134
    .icon-small-tip:before
      color #fff
      vertical-align middle
  .btn
    margin-top 35px
    span
      display inline-block
      width 164px
      height 28px
      background-color #ffa134
      color #fff
      border-radius 3px
      font-size 14px
      line-height 28px
      cursor pointer
.noData-look-brand
  img
    padding-top 120px
  p
    color #fff
    font-size 12px
    font-weight 500
    margin-top 30px
.cityList
  position relative
  background-color rgba(255, 255, 255, 0.1)
  margin 0 20px
  margin-top 10px
  padding 10px 15px
  font-size 14px
  color #fff
  line-height 20px
  padding-left 30px
  border 1px dashed rgba(255, 255, 255, 0.6)
  i
    position absolute
    left 10px
    top 13px
.jurisdiction-wrap-no-data
  padding-bottom 24px
  img
    margin-top 130px
</style>
