<template>
  <div class="enterprise-wrap brand-log">
    <div class="cooperation">
      <div class="w-singed-table">
        <el-table
          :data="brandList.dataList"
          style="width: 100%;"
          @row-dblclick="toContractDetail"
        >
          <el-table-column
            prop="contractCode"
            label="序号"
            type="index"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="description"
            label="操作内容"
            width="500"
          >
          </el-table-column>
          <el-table-column
            prop="userName"
            label="操作人"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="操作时间"
          >
          </el-table-column>
        </el-table>
      </div>
      <div
        class="w-page-sty"
        v-if="brandList.total > 10"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          :current-page='pageNum'
          :page-size="pageSize"
          :total="brandList.total"
        >
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      brandList: {},
      pageSize: 10,
      pageNum: 1
    }
  },
  mounted () {
    this.getSignedByGbidFunc()
  },
  methods: {
    toContractDetail () { },
    getSignedByGbidFunc () {
      this.axios.post(api.getBrandLog, {
        gbid: this.$route.query.gbid,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
        .then((res) => {
          this.brandList = res.data.data
        })
    },
    pageChange (val) {
      this.pageNum = val
      this.getSignedByGbidFunc()
    }
  }
}
</script>

<style lang="stylus" scoped>
.cooperation
  background #272930
  padding 10px 24px 50px 24px
</style>
