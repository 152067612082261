<template>
  <div
    class="brandDetail"
    v-loading.fullscreen.lock="dataLoading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div
      class="wrap"
      v-if="!dataLoading"
    >
      <!-- <div class="w-crumbs-href">
        <router-link
          tag="span"
          :to="{ path: '/selfBrandList?source=1' }"
        >云端品牌库</router-link>
        <i class="el-icon-arrow-right"></i>
        <span class="w-white">{{details.brandName || '--'}}</span>
      </div> -->
      <div class="simpleInfoPanel brandInfoPanel">
        <template v-if="details.isSync">
          <el-tooltip
            class="item"
            effect="dark"
            placement="bottom"
          >
            <span
              class="microAppBtn"
              @click="toCloudBrandDetails()"
            ></span>
            <div slot="content">点击可查看当前品牌在云端品牌库的信息</div>
          </el-tooltip>
        </template>
        <div class="brandImg fl">
          <img
            @click="dialogTableVisible=true"
            v-if="details.brandLogo"
            class="brandLogo"
            :src="details.brandLogo"
          />
          <img
            v-else
            class="brandLogo"
            src="@/assets/images/public/notPic.jpg"
          />
          <span @click="dialogTableVisible=true">更多门店内容</span>
          <img
            v-if="isShowMarker"
            class="identifiton"
            src="@/assets/images/identifiton.png"
            alt=""
          >
        </div>
        <div class="brandMoreInfo">
          <div class="fl">
            {{details.brandName || '--'}}
            {{details.brandEnglishName ? '(' + details.brandEnglishName + ')' : ''}}
            <!-- <span>{{details.isTuoZhan === 1 ? '拓展选址中' : '暂不拓展'}}</span> -->
          </div>
          <div class="fr">
            <!-- <span
              class="btn"
              @click="addDropBrand()"
            >+ 添加到落位方案</span> -->
            <!-- <span
              class="btn"
              @click="addInverstmentTask()"
            >+ 发布招商任务</span> -->
            <span
              class="simpleBtn"
              @click="editBrand(details)"
              v-if="editBtn"
            ><i class="icon-edit"></i>编辑</span>
          </div>
          <div class="clearfix"></div>
          <ul class="brandTagList">
            <li v-if="details.industryTypeName !== ''">{{details.industryTypeName}}</li>
            <li
              :key='index'
              v-for="(item, index) in tagNameList"
              v-show="tagNameList && tagNameList[0] != ''"
            >{{item}}</li>
            <li v-if="details.dicBrandGrade">{{details.dicBrandGrade}}</li>

            <li v-show="details.yearNum !== 0">
              <span>今年计划开店{{details.yearNum}}家</span>
            </li>
            <template v-if="details && details.othertag">
              <li
                class="style1"
                v-for="(item, index) in otherTag"
                :key="'other' + index"
              >{{item}}
              </li>
            </template>
            <li
              class="style2"
              v-show="details.financing === 1"
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom"
              >
                <span>资本青睐品牌</span>
                <div slot="content">近两年已获机构资金投入，未来发展提速可能性大的品牌</div>
              </el-tooltip>
            </li>
            <li
              v-for="(item, index) in customLabel"
              :key='"cutom" + index'
              v-show="customLabel && customLabel[0] !== ''"
              class="style"
            >{{item}}
            </li>
            <li
              v-for="(item, index) in operationFeature"
              :key="'operation' + index"
              v-show="operationFeature && operationFeature[0] !== ''"
              class="style2"
            >{{item}}
            </li>
            <li
              v-show="trendName && trendName[0] !== ''"
              v-for="(item, index) in trendName"
              :key="index"
              class="style2"
            >{{item}}
            </li>
            <li
              class="style2"
              v-if="details.newBranktop"
            >
              {{details.newBranktop}}
            </li>
            <li v-if="details.brandClass">
              {{details.brandClass}}
            </li>
            <li v-if="details.cooperationship">
              {{details.cooperationship}}
            </li>
            <li v-if="details.kaiDianMode">{{details.kaiDianMode}}</li>
          </ul>
        </div>
        <div class="clearfix"></div>
        <div
          class="pr"
          v-if="details.content"
        >
          <div
            class="brandDescribe"
            :class="{'heightLess': !isShowMore}"
            v-html="details.content"
          >
          </div>
          <span
            class="handleDescribe"
            :class="{'bottomMore': !isShowMore}"
            @click="isShowMore=!isShowMore"
          >
            <i :class="isShowMore?'icon-showLess':'icon-showMore'"></i>
          </span>
        </div>
      </div>
      <div
        class="simpleInfoPanel mt15 no-box-shadow"
        id="navTop"
        :class="searchBarFixed"
      >
        <ul class="navMenuList">
          <li
            v-for="(item, index) in navMenuList"
            :key="index"
            :class="{
              'currentActive': item.value===navMenuValue,
              'hot': item.value==='dataAnalysis' || item.value==='news'}"
            @click="navMenuValue=item.value"
          >
            {{item.name}}
            <span
              v-if="item.value==='expandDemand' && details.tuozhanNum > 0"
              class="combined"
            >{{item.value==='expandDemand' && details.tuozhanNum > 0 ?details.tuozhanNum:''}}</span>
            <span
              v-if="item.value==='contactWay' && details.linkmanNum > 0"
              class="combined"
            >{{item.value==='contactWay' && details.linkmanNum > 0 ?details.linkmanNum:''}}</span>
          </li>
        </ul>
      </div>
      <div id="content">
        <div
          v-if="details"
          :is="navMenuValue"
          :details="details"
          :lookNumber="lookNumber"
          :interestsData="interestsData"
          @tabClick="tabClick"
          @updateNumber="updateNumber"
          @updateMarker="updateMarker"
        ></div>
      </div>
    </div>
    <div v-if="dropBrand">
      <add-drop
        v-if="details !== {}"
        :dropEdit="6"
        :brandDetails="details"
        @hideAddDropFunc="hideAddDropFunc"
      ></add-drop>
    </div>
    <div v-if="invesTask">
      <inves-task
        v-if="details !== {}"
        :taskEdit='6'
        :brandDetails="details"
        @hideTaskFunc="hideTaskFunc"
      ></inves-task>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogTableVisible"
    >
      <el-carousel
        :interval="4000"
        type="card"
      >
        <el-carousel-item
          v-for="(item, index) in brandImg"
          :key="index"
        >
          <img
            :src="item.url"
            width="100%"
            height="100%"
          >
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import brandSituation from '@/views/enterpriseBrandLibrary/brandDetail/components/brandSituation.vue'
import storeDistribution from '@/views/enterpriseBrandLibrary/brandDetail/components/storeDistribution.vue'
import dataAnalysis from '@/views/enterpriseBrandLibrary/brandDetail/components/dataAnalysis.vue'
import expandDemand from '@/views/enterpriseBrandLibrary/brandDetail/components/expandDemand.vue'
import contactWay from '@/views/enterpriseBrandLibrary/brandDetail/components/contactWay.vue'
import cooperationInfo from '@/views/enterpriseBrandLibrary/brandDetail/components/cooperationInfo.vue'
import merchantsRecords from '@/views/enterpriseBrandLibrary/brandDetail/components/merchantsRecords.vue'
import operationLog from '@/views/enterpriseBrandLibrary/brandDetail/components/operationLog.vue'
import invesTask from '@/views/workBench/components/investmentTask.vue'
import addDrop from '@/views/workBench/components/addDropBrand.vue'
export default {
  name: 'brandDetail',
  components: {
    brandSituation,
    storeDistribution,
    dataAnalysis,
    expandDemand,
    contactWay,
    cooperationInfo,
    merchantsRecords,
    operationLog,
    invesTask,
    addDrop
  },
  data () {
    return {
      details: {},
      tagNameList: [],
      trendName: [],
      otherTag: [],
      operationFeature: [],
      customLabel: [],
      brandImg: [],
      isShowMore: false,
      dataLoading: true,
      dialogTableVisible: false,
      brandInfo: [],
      navMenuValue: 'brandSituation',
      navMenuList: [
        {
          name: '品牌概况',
          value: 'brandSituation'
        },
        // {
        //   name: '开店分布',
        //   value: 'storeDistribution'
        // },
        // {
        //   name: '数据分析',
        //   value: 'dataAnalysis'
        // },
        {
          name: '拓展需求',
          value: 'expandDemand'
        },
        {
          name: '联系方式',
          value: 'contactWay'
        },
        {
          name: '招商对接记录',
          value: 'merchantsRecords'
        },
        {
          name: '签约合同',
          value: 'cooperationInfo'
        },
        {
          name: '操作日志',
          value: 'operationLog'
        }
      ],
      lookNumber: 0,
      hasRemark: 0,
      searchBarFixed: '',
      invesTask: false,
      dropBrand: false,
      dataNum: {
        brandExpandNum: '', // 拓展需求数
        linkmanNum: '', // 联系方式数
        newsNum: '', // 新闻资讯数
        openShopNum: '' // 在营业店铺数量
      },
      interestsData: {},
      isShowMarker: false,
      pubplan: false,
      addbrand: false,
      userAuthorities: [],
      userAuthoritiesAdd: [],
      brandLibrary: [],
      editBtn: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 70
    })
    if (this.userAuthorities.length) {
      this.addbrand = this.userAuthorities[0].func.indexOf('addbrand') !== -1
    }
    this.userAuthoritiesAdd = this.user.confIds.filter(item => {
      return item.id === 74
    })
    if (this.userAuthoritiesAdd.length) {
      this.pubplan = this.userAuthoritiesAdd[0].func.indexOf('add') !== -1
    }
    this.brandLibrary = this.user.confIds.filter(item => {
      return item.id === 164
    })
    this.editBtn = this.brandLibrary[0].func.indexOf('edit') > -1
    this.interestsData = JSON.parse(localStorage.getItem('wisdom_interests'))
    this.getDataBrandDetail()
    // this.getBrandDetailLookNumFunc()
    if (this.$route.query.from) {
      this.navMenuValue = this.$route.query.from
    }
    this.axios.post(api.getBrandDetailIsShow,
      {
        brand_id: this.$route.query.brandId,
        source: this.$route.query.source
      }
    )
      .then((res) => {
        if (res.data.data) {
          this.isShowMarker = true
        } else {
          this.isShowMarker = false
        }
      })
    // window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    updateMarker (type) {
      if (type) {
        this.isShowMarker = true
      }
    },
    handleScroll (e) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = document.querySelector('#navTop').offsetTop
      const contentTop = document.querySelector('#content').offsetTop
      if (scrollTop > offsetTop) {
        this.searchBarFixed = 'isFixed'
      }
      if (scrollTop < contentTop) {
        this.searchBarFixed = ''
      }
    },
    lookMore (type) {
      this.navMenuValue = type
    },
    tabClick (type) {
      this.navMenuValue = type
    },
    getBrandDetailLookNumFunc () {
      this.axios.post(api.getBrandDetailLookNum)
        .then((res) => {
          this.lookNumber = res.data.data
        })
    },
    getDataBrandDetail () {
      const params = {
        brand_id: this.$route.query.brandId,
        gbid: this.$route.query.gbid,
        source: this.$route.query.source
      }
      this.axios.post(api.getBrandInfo, params).then(res => {
        if (res.data.code === 0) {
          this.details = res.data.data
          this.hasRemark = this.details.hasRemark
          if (res.data.data.tagname) {
            this.tagNameList = res.data.data.tagname.split(',')
          }
          if (res.data.data.trendName) {
            this.trendName = res.data.data.trendName.split(',')
          }
          // this.otherTag = res.data.data.othertag.split(',')
          if (res.data.data.othertag !== '') {
            // this.selectOther = res.data.othertag.split(',')
            const arr = []
            const otherArr = res.data.data.othertag.split(',')
            for (let i = 0; i < otherArr.length; i++) {
              if (otherArr[i].replace(/\s+/g, '') !== '') {
                arr.push(otherArr[i])
              }
            }
            this.otherTag = arr
          }
          if (res.data.data.operationFeature) {
            this.operationFeature = res.data.data.operationFeature.split(',')
          }
          // this.customLabel = res.data.data.customLabel ? res.data.data.customLabel.split(',') : []
          if (res.data.data.customLabel !== '') {
            const selfArr = res.data.data.customLabel.split(',')
            const customArr = []
            for (let i = 0; i < selfArr.length; i++) {
              if (selfArr[i].replace(/\s+/g, '') !== '') {
                customArr.push(selfArr[i])
              }
            }
            this.customLabel = customArr
          }
          this.dataLoading = false
        }
      })
      this.axios.post(api.getBrandImage, params).then(res => {
        if (res.data.code === 0) {
          this.brandImg = res.data.data.brandPicList
        }
      })
    },
    updateNumber () {
      this.axios.post(api.getBrandDetailLookNum)
        .then((res) => {
          this.lookNumber = res.data.data
        })
    },
    toCloudBrandDetails () {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + this.details.brandId + '&source=1' + '&questionState=0' + '&gbid=' + this.details.gbid, '_blank')
    },
    toDetails (id, questionState, gbid) {
      window.open('http://' + window.location.host + '/#/brandDetail?id=' + id + '&source=1' + '&questionState=' + questionState + '&gbid=' + gbid, '_blank')
    },
    toNewsDetail (item) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent(item.title),
          newsId: item.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    toBrandIndex () {
      window.open('http://' + window.location.host + '/#/brandDetail/brandIndex?id=' + this.$route.query.brandId + '&source=4&questionState=' + this.questionState + '&gbid=' + this.$route.query.gbid, '_blank')
    },
    addDropBrand () {
      if (!this.addbrand) {
        this.$message.error('暂无权限，如需开通权限，请联系管理员开通')
      } else {
        this.dropBrand = true
        document.body.style.overflowY = 'hidden'
      }
    },
    hideAddDropFunc () {
      this.dropBrand = false
      document.body.style.overflowY = 'auto'
    },
    // 点击发布招商任务
    addInverstmentTask () {
      if (!this.pubplan) {
        this.$message.error('暂无权限，如需开通权限，请联系管理员开通')
      } else {
        this.invesTask = true
        document.body.style.overflowY = 'hidden'
      }
    },
    hideTaskFunc (type) {
      this.invesTask = false
      // if (type === true) {
      //   this.navMenuValue = 'merchantsRecords'
      // }
      document.body.style.overflowY = 'auto'
    },
    followClick () {
      if (this.hasRemark === 0) {
        this.axios.post(api.followBrand,
          {
            brandId: this.$route.query.brandId,
            uid: this.user.userId,
            gbid: parseInt(this.$route.query.gbid)
          })
          .then((res) => {
            this.hasRemark = 1
          })
      } else if (this.hasRemark === 1) {
        this.axios.post(api.cancalFollowBrand,
          {
            brandId: this.$route.query.brandId,
            uid: this.user.userId,
            gbid: parseInt(this.$route.query.gbid)
          })
          .then((res) => {
            this.hasRemark = 0
          })
      }
    },
    editBrand (item) {
      if (this.brandLibrary[0].func.indexOf('edit') === -1) {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
        return false
      } else {
        localStorage.setItem('brandId', item.brandId)
        localStorage.setItem('gbid', item.gbid)
        localStorage.setItem('brandName', item.brandName)
        if (document.domain.indexOf('local.winshangdata') > -1) {
          window.open('http://' + document.domain + ':8030/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName)
        } else if (window.location.href.indexOf('http://') > -1) {
          console.log('http://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName)
          window.parent.postMessage({ changeIFrame: 'http://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName }, '*')
        } else {
          console.log('https://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName)
          window.parent.postMessage({ changeIFrame: 'https://' + document.domain + '/brand/#/issue/basicMsg?brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName }, '*')
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.brandDetail
  overflow hidden
  .hot:after
    top 14px
    right -33px
  .wrap
    // width 1280px
    // margin 10px 30px
    margin-bottom 40px
    .simpleInfoPanel
      color #fff
      width 100%
      position relative
      .btnCommon:hover, .btnCommon:focus
        border 1px solid #fff !important
        color #fff !important
      .brandImg
        width 106px
        height 96px
        position relative
        margin 23px 0 0 32px
        .brandLogo
          width 106px
          height 96px
          cursor pointer
        span
          display inline-block
          width 100%
          height 24px
          line-height 24px
          color #fff
          text-align center
          cursor pointer
          font-size 12px
          position absolute
          left 0
          bottom 0
          background rgba(0, 0, 0, 0.7)
        .identifiton
          position absolute
          left 0
          top 0
      .brandMoreInfo
        width calc(100% -160px)
        margin-top 28px
        overflow hidden
        .fl
          margin-left 20px
          font-size 24px
          span
            font-size 12px
            background rgba(54, 255, 177, 0.15)
            display inline-block
            padding 3px
            color #1EFF98
            vertical-align middle
            margin-left 10px
        .fr
          margin-right 10px
          span
            display inline-block
            font-size 12px
            border-radius 2px
            height 20px
            line-height 20px
            padding 0 16px
            cursor pointer
            margin-right 16px
          span.btn
            border 1px solid #FFA134
            color #FFA134
          span.simpleBtn
            background rgba(255, 255, 255, 0.1)
        ul.brandTagList
          margin-top 10px
          font-size 12px
          li
            display inline-block
            background rgba(255, 255, 255, 0.1)
            color rgba(255, 255, 255, 0.7)
            height 22px
            line-height 22px
            border-radius 11px
            padding 0 16px
            margin-left 18px
            margin-top 10px
          li.style1
            background rgba(222, 194, 167, 0.1)
            color #FFC690
          li.style2
            background rgba(255, 88, 36, 0.1)
            color #FE8F69
      .brandDescribe
        position relative
        font-size 12px
        box-sizing border-box
        width 95%
        line-height 22px
        margin 24px auto
        border 1px dashed rgba(255, 255, 255, 0.3)
        padding 10px
        padding-right 30px
        border-radius 3px
        height auto
        font-weight 300
      .heightLess
        height 60px
        text-overflow ellipsis
        overflow hidden
        word-break break-all
      .bottomMore
        bottom 10px !important
      .handleDescribe
        font-size 12px
        position absolute
        right 50px
        bottom 8px
        z-index 999
        cursor pointer
        color rgba(255, 161, 52, 1)
        background #272930
      .dateStyle
        color rgba(255, 255, 255, 0.5)
        font-size 12px
        margin 0 10px
    .no-box-shadow
      border-radius 3px 3px 0 0
      box-shadow none
    .brandInfoPanel
      // min-height 240px
      .microAppBtn
        display inline-block
        background-image url('~@/assets/images/brandDetail/microApp.png')
        background-repeat no-repeat
        cursor pointer
        width 28px
        height 28px
        position absolute
        top 0
        right 0
      .icon-brandInfo
        margin-left 40px
      .information-info
        font-size 12px
        margin-bottom -5px
        // margin-top 15px
    .brandMyspic
      box-sizing border-box
      height 50px
      line-height 50px
      background linear-gradient(90deg, rgba(255, 130, 57, 0.14) 0%, rgba(39, 41, 48, 0.5) 80%)
      font-size 14px
      &:before
        content ''
        display inline-block
        width 1px
        height 32px
        background rgba(255, 255, 255, 0.1)
        position relative
        top 9px
        left 127px
      .panelTitle
        display inline-block
        width 140px
        height 18px
        background-image url('~@/assets/images/brandDetail/brandMyspic.png')
        background-repeat no-repeat
        background-position 24px
      span.num
        font-size 18px
        font-weight 800
        // color #FFA134
      span.numExplain
        color rgba(255, 255, 255, 1)
        margin 0 18px 0 10px
      .el-button
        position absolute
        right 20px
        top 12px
        width auto
        height 28px
        padding 0 7px !important
        border-radius 2px
        font-size 12px
        font-weight 400
    .navMenuList
      height 47px
      line-height 47px
      margin-top 10px
      border-bottom 1px solid rgba(226, 226, 226, 0.1)
      li
        display inline-block
        margin 0 24px
        cursor pointer
        position relative
        font-size 18px
        &:hover
          border-bottom 2px solid #FFA134
      li.currentActive:before
        content ''
        width 100%
        height 2px
        background #FFA134
        position absolute
        bottom -1px
        left 0
.brandDetail>>>
  .el-dialog
    width 70%
    .el-carousel__container
      height 350px
  .el-dialog, .el-pager li
    background none
  .el-carousel__item:nth-child(2n)
    background-color #99a9bf
  .el-carousel__item:nth-child(2n+1)
    background-color #d3dce6
.like-list-wrap
  background-color #272930
  margin-top 16px
  // margin-bottom 16px
  padding-top 24px
  padding-bottom 40px
  .brand-list
    margin-top 38px
    ul
      width 1200px
      margin auto
      li
        display inline-block
        cursor pointer
        width 220px
        vertical-align top
        position relative
        width 250px
        height 150px
        background-color #fff
        margin 10px
        width 220px
        height 160px
        overflow hidden
        .item-image
          display inline-block
          width 55px
          height 53px
          background no-repeat center center
          background-size 100%
          margin-left 15px
          margin-top 10px
        .item-brandName
          position absolute
          top 10px
          left 80px
          font-weight 600
          width 130px
          height 33px
          overflow hidden
          text-overflow ellipsis
          -webkit-line-clamp 2
          -webkit-box-orient vertical
          font-size 14px
          color #333
        .item-info-need
          font-size 12px
          color #333
          margin-left 15px
          margin-top 10px
          p
            line-height 20px
            padding 2px 0
        .label
          position absolute
          bottom 10px
          left 12px
          background #FFF9F3
          border-radius 9px
          padding 3px 8px
          color #C9BAAB
          font-size 12px
.not-follow
  color #fff
.yes-follow
  color #ffa134
.followed
  background rgba(255, 161, 52, 0.1) !important
  color #ffa134
.isFixed
  position fixed !important
  top 0px
  z-index 11111
  width 1280px !important
  margin-top 0
.applets-wrap
  text-align center
.combined
  display inline-block
  width 28px
  height 20px
  background url('~@/assets/images/CombinedShape.png') no-repeat
  background-size 100%
  color #fff
  line-height 15px
  font-size 12px
  text-align center
  vertical-align middle
.brandDetail
  .el-tooltip
    outline none
</style>
