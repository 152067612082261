<template>
  <div
    class="enterprise-wrap"
    v-loading='loading'
  >
    <div class="cooperation">
      <div class="w-singed-table">
        <el-table
          :data="brandList.dataList"
          style="width: 100%"
          @row-click="toSignDetail"
        >
          <el-table-column
            prop="projectName"
            label="项目名称"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="contractCode"
            label="商铺合同号"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="merchantName"
            label="商户"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="storeName"
            label="店铺"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="signDate"
            label="签约日期"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="effectDate"
            label="合同生效日期"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="cutoffDate"
            label="合同失效日期"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="tenancy"
            label="合同租期(月)"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="createUserName"
            label="创建人"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            width="180"
          >
          </el-table-column>
        </el-table>
      </div>
      <div
        class="w-page-sty"
        v-if="brandList && brandList.dataList && brandList.dataList.length > 10"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="pageChange"
          :current-page='pageNum'
          :page-size="pageSize"
          :total="brandList.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      pageSize: 10,
      pageNum: 1,
      brandList: {},
      modulesList: []
    }
  },
  computed: {
    ...mapGetters({
      token: 'token'
    })
  },
  created () {
    this.getSignedByGbidFunc()
    this.getModularListFunc()
  },
  methods: {
    getSignedByGbidFunc () {
      this.axios.post(api.getSignedByGbid, {
        gbid: this.$route.query.gbid,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      })
        .then((res) => {
          this.brandList = res.data.data
        })
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    pageChange (val) {
      this.pageNum = val
      this.getSignedByGbidFunc()
    },
    toContractDetail () { },
    //
    toSignDetail (detail) {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + detail.contractCode + '&contractId=' + detail.contractId + '&mallCode=' + detail.mallCode + '&menuCode=12&tokenval=' + this.token + '&operateStep=3', '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '您暂无查看合同详情的权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.cooperation
  background #272930
  padding 10px 24px 50px 24px
.w-singed-table
  background #272930
  box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
  border-radius 3px
  margin-top 15px
  box-sizing border-box
  padding 0 24px
  padding-bottom 35px
  table
    width 100%
    thead
      tr
        height 54px
        line-height 54px
        color #999
        font-size 12px
        td
          border-bottom 1px solid rgba(216, 216, 216, 0.1)
          // text-indent 14px
          padding-left 10px
    tbody
      tr
        height 47px
        line-height 47px
        color #ffffff
        font-size 12px
        &:hover td
          background-color #23252b
        td
          border-bottom 1px solid rgba(216, 216, 216, 0.1)
          // text-indent 14px
          padding-left 10px
</style>
