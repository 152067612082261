<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div class="demand">
      <div
        class="demand-content"
        v-if="isShowData"
      >
        <div
          class="region"
          v-if="cityList && cityList.length > 0"
        >
          <div class="region-head">计划拓展区域</div>
          <div class="region-list">
            <ul>
              <li
                :class="{active:currentTab === index}"
                :key="index"
                :index="index"
                v-for="(item, index) in details.developmentDemand"
                @click="toRegion(item, index)"
              >{{item.regionName}}</li>
            </ul>
          </div>
          <div class="city-list">
            <ul>
              <li
                :key="index"
                :index="index"
                v-for="(item, index) in cityList"
              >
                <span>{{item.label}}:</span>
                <i
                  v-for="(list, key) in item.children"
                  :key="key"
                >{{list.label}}</i>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="property"
          v-if="details.propertyContent"
        >
          <div class="property-head">物业条件要求</div>
          <div
            class="property-main"
            v-html="details.propertyContent"
          ></div>
        </div>
        <div
          class="property"
          v-if="trimsStr !== ''"
        >
          <div class="property-head">其他拓展要求</div>
          <div
            class="property-main"
            v-html="details.otherOpenShopNeed"
          ></div>
        </div>
      </div>
      <div
        class="jurisdiction-wrap-no-data"
        v-if="isShowContent"
      >
        <img
          src="@/assets/images/no_list_data.png"
          alt=""
        >
        <p>暂无拓展需求</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import api from '@/api'
export default {
  data () {
    return {
      loading: false,
      currentTab: 0,
      trimsStr: '',
      isShowBrandDetail: false,
      currentRid: '',
      provinceList: [],
      cityList: [],
      isShowContent: false,
      isShowData: false
    }
  },
  props: {
    details: {
      type: Object
    },
    lookNumber: {
      type: Number
    },
    interestsData: {
      type: Object
    }
  },
  created () {
    this.deleteHtmlTag()
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    if (this.details && this.details.developmentDemand.length > 0 && this.details.developmentDemand[0].children.length > 0) {
      this.isShowContent = false
      this.isShowData = true
      this.cityList = this.details.developmentDemand[0].children
    } else {
      if (!this.details.propertyContent && !this.details.otherOpenShopNeed) {
        this.isShowContent = true
        this.isShowData = false
      } else {
        this.isShowContent = false
        this.isShowData = true
      }
    }
  },
  methods: {
    toRegion (item, index) {
      this.currentTab = index
      for (const list of this.details.developmentDemand) {
        if (list.regionID === item.regionID) {
          this.cityList = list.children
        }
      }
    },
    deleteHtmlTag () {
      this.trimsStr = this.details.otherOpenShopNeed.replace(/<[^>]+>|&[^>]+;/g, '').trim() // 去掉所有的html标签和&nbsp;之类的特殊符合
    }
  }
}
</script>

<style lang="stylus" scoped>
.demand
  margin 0px auto
  // padding 30px 0 0 0
  background-color #272930
  .demand-head
    border-left 2px solid #FFA134
    padding-left 10px
    margin-left 20px
    color #fff
  .demand-content
    padding 0px 20px 30px 20px
    .region
      .region-head
        width 100%
        color #e5e5e5
        font-size 16px
        border-left 2px solid #FFA134
        padding-left 10px
      .region-list
        margin-top 35px
        height 50px
        padding-left 14px
        li
          display inline-block
          width 82px
          height 30px
          text-align center
          line-height 30px
          border 1px solid #979797
          color #979797
          margin-right 15px
          cursor pointer
          font-size 14px
        .active
          border 1px solid #ffa134
          background-color #ffa134
          color #fff
        li:hover
          border 1px solid #ffa134
          background-color #ffa134
          color #fff
      .city-list
        li
          line-height 40px
          color #fff
          font-size 14px
          span
            display inline-block
            width 60px
            margin-right 10px
            color #ccc
            text-align left
            margin-left 15px
          i
            margin-right 10px
    .property
      margin-top 20px
      .property-head
        width 100%
        border-left 2px solid #ffa134
        padding-left 10px
        color #e5e5e5
        font-size 16px
      i
        font-style normal
      .property-main
        margin-top 10px
        line-height 30px
        color #ccc
        font-size 14px
        padding-left 14px
    .other
      margin-top 20px
      .other-head
        width 100%
        border-bottom 2px solid #454a54
        color #e5e5e5
        font-size 16px
        line-height 42px
        background-color #323a48
        padding-left 10px
      .other-content
        margin-top 20px
        line-height 36px
        color #fff
        font-size 14px
.need
  margin-bottom 20px
  font-weight 100
  span
    display inline-block
    width 300px
    color #fff
    margin-top 15px
    font-size 14px
    margin-left 12px
    font-weight 100
.noData
  padding-left 20px
  margin-top 30px
  color #fff
  padding-bottom 32px
  text-align center
  p
    margin-top 20px
    margin-right 20px
    font-size 14px
  .no-list-data
    width 136px
    height 98px
    background-size 100%
.analysis-wrap-tips
  height 400px
  background-color #282A30
  text-align center
.look-detail-wrap
  padding-top 120px
  .text
    width 530px
    height 38px
    line-height 38px
    font-size 14px
    margin auto
    background-color rgba(0, 0, 0, 0.1)
    color #fff
    i
      color #ffa134
    .icon-small-tip:before
      color #fff
      vertical-align middle
  .btn
    margin-top 35px
    span
      display inline-block
      width 164px
      height 28px
      background-color #ffa134
      color #fff
      border-radius 3px
      font-size 14px
      line-height 28px
      cursor pointer
.jurisdiction-wrap-no-data
  color #ffffff
  text-align center
  min-height 170px
  padding-top 80px
  background #272930
  padding-bottom 24px
  img
    width 136px
    height 98px
    margin-bottom 20px
  p
    font-size 14px
    line-height 18px
</style>
